import type { MetaFunction } from '@remix-run/node';
import { Outlet } from '@remix-run/react';

export const meta: MetaFunction = () => {
  return [{ title: "Moody's Login" }, { name: 'description', content: `Login with your Moody's Account` }];
};

export default function AuthLayout() {
  return (
    <div className="sm:bg-gray-1/25 dark:bg-gray-10 dark:sm:bg-gray-10 flex h-full flex-col items-center overflow-y-auto bg-white pb-10 pt-10 sm:pt-[6.25rem]">
      <div className="sm:border-gray-1 sm:dark:bg-gray-10 flex w-full flex-col items-center px-6 py-8 sm:max-w-md sm:rounded-2xl sm:border sm:bg-white sm:px-8 sm:shadow dark:border-white/10">
        <img
          className="mb-20 h-12 w-auto dark:brightness-0 dark:invert dark:filter"
          src="/images/mdy_logo_cmyk_moodysblue.png"
          alt="Moody's"
        />

        <Outlet />
      </div>
    </div>
  );
}
